import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button } from "src/templates"

const Introduction = ({title, imageLeft, description, callToAction}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const NewlineText = (text) => {
  return text.split('\n').map((str, index) => (
   <Box key={index}
    sx={{ 
     display: "flex",
     marginBottom: 2,
     alignItems: "center"
    }}
   >
    <Box
     sx={{ 
      display: "inline-flex",
      border: `2px solid ${theme.palette.primary.main}`,
      padding: 1,
      borderRadius: "100%",
      width: 32,
      height: 32,
      justifyContent: "center",
      alignItems: "center",
      marginRight: 2,
     }}
    >
     <Typography sx={{width: "12px"}} align="center" display="inline" color="primary.main" variant="h6">
      {str.split(". ")[0]}
     </Typography>
    </Box>
    <Box>
     <Typography display="inline">{str.split(". ")[1]}</Typography>
    </Box>
    <br/>
   </Box>
  ));
 }
   
 const styles = {
  introductionBackground: {
   padding: "40px 0px",
  },
  introductionContainer: {
   display: 'flex',
   alignItems: 'center',
   padding: !lg ? "0px 10% !important" : "auto",
   flexDirection: !lg ? 'row' : 'column',
   rowGap: !lg ? 20 : 10,
   columnGap: 5,
  },
  image: {
   width: "100%",
   maxWidth: "480px",
   minWidth: "172px",
  },
  introductionTexts: {
   width: !lg ? '60%' : 'auto',
  },
  description: {
   padding: '35px 0px',
  },
 }
 
 return (
  <Box sx={styles.introductionBackground}>
   <Container sx={styles.introductionContainer}>
    <Box>
     <Box component="img" sx={styles.image} src={imageLeft} alt=""/>
    </Box>
    <Box sx={styles.introductionTexts}>
     <Box>
      <Typography align={lg ? "center" : "left"} variant="h3" color="primary.dark" >{title}</Typography>
     </Box>
     <Box sx={styles.description}>
      <Typography component="span" color="text.primary">{NewlineText(description)}</Typography>
     </Box>
     <Box sx={{ display: "flex", justifyContent: lg ? "center" : "right" }}>
      {callToAction && (
       <a href={callToAction?.link} style={{ textDecoration: 'none'}}>
        <Button variant="outlined">
         {callToAction?.text}
        </Button>
       </a>
      )
      }
     </Box>
    </Box>
   </Container>
  </Box>
 )}

export { Introduction }